import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexPageTemplate from '../templates/IndexPage.template'
import dataStateConstants from '../constants/dataState.constants'

const ReklamaPage = () => {
  const { reklama } = dataStateConstants

  return (
    <Layout>
      <SEO title="RDB Group | Reklama" />
      <IndexPageTemplate
        initial={reklama}
      />
    </Layout>
  )
}

export default ReklamaPage
